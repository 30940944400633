import { Box } from '@medely/ui-kit';
import React from 'react';
import { DxGyCard } from './CampaignCards/DxGyCampaignCards/DxGyCard';
import { SpecialtiesCard } from './CampaignCards/SpecialtiesCard';
import { CampaignDataProps } from './CampaignCards/Interface';

type CampaignsNextStepCardsProps = {
  data: CampaignDataProps[];
};

export const CampaignsNextStepCards = (allCampaigns: CampaignsNextStepCardsProps) => {
  const dxgyCampaigns = allCampaigns.data.filter((c) => c.slug === 'dxgy');
  const specialtiesCardData = allCampaigns.data.filter((c) => c.slug === 'update_specialties');
  return (
    <>
      {dxgyCampaigns?.map((dxgyCampaign) => (
        <Box key={dxgyCampaign?.starts_time.toString()}>
          <DxGyCard data={dxgyCampaign} />
        </Box>
      ))}
      {specialtiesCardData.map((specialtyCampaign) => (
        <Box key={specialtyCampaign?.starts_time.toString()}>
          <SpecialtiesCard specialCardInfo={specialtyCampaign} />
        </Box>
      ))}
    </>
  );
};
