/* eslint-disable medely/noUseCallbackUseMemo */
import { useAnalytics } from '@medely/ui-kit';
import { useCallback } from 'react';
import { debounceLeading } from '@mp/utils';
import {
  Listing,
  IViewedAssignmentAnalyticsMetadata,
  IViewedJobAnalyticsMetadata,
} from '../../utils/tracking';
import { IAssignment, IPosition, IJob } from '@medely/types';

export type AssignmentFilterEventMetadata = {
  contract_length?: {
    minContractLength?: number;
    maxContractLength?: number;
  };
  ignore_location_type_ids?: number[];
  location_type_ids?: number[];
  minimum_rate?: number;
  new_assignment: boolean;
  orderBy: string;
  position_ids: IPosition['id'][];
  schedule: string[];
  shift_length?: { minShiftLength?: number; maxShiftLength?: number };
  starts_date: string;
};

export type PerDiemFilterEventMetadata = {
  minimum_rate?: number;
  position_ids: IPosition['id'][];
  schedule: string[];
  shift_type?: string[];
};

enum CONTEXTS {
  ASSIGNMENT_LIST = 'Assignment List',
  ASSIGNMENT_DETAILS = 'Assignment Details',
  LIST_ITEM = 'List Item',
  JOB_LIST = 'Job List',
  JOB_DETAILS = 'Job Details',
  RESET_BUTTON = 'Reset Button',
}

enum ACTIONS {
  VIEWED = 'Viewed',
  CLICKED = 'Clicked',
}

const DEFAULT_DEBOUNCE_TIME = 500;

export type ListViewId = number | string;

type TrackAssignmentListViewPayload = {
  eligible_count: number;
  listings: Listing[];
  filters?: AssignmentFilterEventMetadata;
  count: number;
  distance?: number;
  list_view_id: ListViewId;
  page_number: number;
  tab: string;
};

type TrackAssignmentListItemClickedPayload = {
  assignment_id: IAssignment['id'];
  list_view_id: ListViewId;
  position_in_list: number;
  pay_per_week_cents: IAssignment['pay_per_week_cents'];
  one_time_bonus_burden_cents: IAssignment['one_time_bonus_burden_cents'];
};

type TrackAssignmentListResetPayload = { travel?: string };

type TrackJobListViewPayload = {
  eligible_count: number;
  listings: Listing[];
  filters: PerDiemFilterEventMetadata;
  distance: number;
  count: number;
  list_view_id: ListViewId;
  page_number: number;
  tab: string;
};

type TrackJobListItemClickedPayload = {
  job_id: IJob['id'];
  list_view_id: ListViewId;
  position_in_list: number;
  total_payout_bonus_cents: number;
};
interface IUseFindJobsEvent {
  trackAssignmentListView: (payload: TrackAssignmentListViewPayload) => void;
  trackAssignmentListItemClicked: (payload: TrackAssignmentListItemClickedPayload) => void;
  trackAssignmentDetailsView: (payload: IViewedAssignmentAnalyticsMetadata) => void;
  trackAssignmentListReset: (payload?: TrackAssignmentListResetPayload) => void;
  trackJobListView: (payload: TrackJobListViewPayload) => void;
  trackJobListItemClicked: (payload: TrackJobListItemClickedPayload) => void;
  trackJobDetailsView: (payload: IViewedJobAnalyticsMetadata) => void;
  trackJobListReset: (payload?: TrackAssignmentListResetPayload) => void;
}

export const useFindJobsEvent = (): IUseFindJobsEvent => {
  const analytics = useAnalytics();

  const trackAssignmentListView = useCallback(
    debounceLeading((payload: TrackAssignmentListViewPayload) => {
      analytics.track({
        context: CONTEXTS.ASSIGNMENT_LIST,
        subcontext: null,
        action: ACTIONS.VIEWED,
        ...payload,
      });
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  const trackAssignmentListItemClicked = useCallback(
    debounceLeading((payload: TrackAssignmentListItemClickedPayload) => {
      analytics.track({
        context: CONTEXTS.ASSIGNMENT_LIST,
        subcontext: CONTEXTS.LIST_ITEM,
        action: ACTIONS.CLICKED,
        ...payload,
      });
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  const trackAssignmentDetailsView = useCallback(
    debounceLeading((payload: IViewedAssignmentAnalyticsMetadata) => {
      analytics.track({
        context: CONTEXTS.ASSIGNMENT_DETAILS,
        subcontext: null,
        action: ACTIONS.VIEWED,
        ...payload,
      });
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  const trackAssignmentListReset = useCallback(
    debounceLeading((payload: TrackAssignmentListResetPayload = {}) => {
      analytics.track({
        context: CONTEXTS.ASSIGNMENT_LIST,
        subcontext: CONTEXTS.RESET_BUTTON,
        action: ACTIONS.CLICKED,
        ...payload,
      });
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  const trackJobListView = useCallback(
    debounceLeading(
      async (payload: TrackJobListViewPayload) =>
        analytics.track({
          context: CONTEXTS.JOB_LIST,
          subcontext: null,
          action: ACTIONS.VIEWED,
          ...payload,
        }),
      DEFAULT_DEBOUNCE_TIME,
    ),
    [],
  );

  const trackJobListItemClicked = useCallback(
    debounceLeading((payload: TrackJobListItemClickedPayload) => {
      analytics.track({
        context: CONTEXTS.JOB_LIST,
        subcontext: CONTEXTS.LIST_ITEM,
        action: ACTIONS.CLICKED,
        ...payload,
      });
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  const trackJobDetailsView = useCallback(
    debounceLeading((payload: IViewedJobAnalyticsMetadata) => {
      analytics.track({
        context: CONTEXTS.JOB_DETAILS,
        subcontext: null,
        action: ACTIONS.VIEWED,
        ...payload,
      });
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  const trackJobListReset = useCallback(
    debounceLeading((payload: TrackAssignmentListResetPayload = {}) => {
      analytics.track({
        context: CONTEXTS.JOB_LIST,
        subcontext: CONTEXTS.RESET_BUTTON,
        action: ACTIONS.CLICKED,
        ...payload,
      });
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  return {
    trackAssignmentListView,
    trackAssignmentListItemClicked,
    trackAssignmentDetailsView,
    trackAssignmentListReset,
    trackJobListView,
    trackJobListItemClicked,
    trackJobDetailsView,
    trackJobListReset,
  };
};

export default useFindJobsEvent;
