export const gateNames = {
  displayJobCancelButtonAtPageTop: 'bring_cancel_button_to_top_of_job_details_page',
  docAi530Verification: 'doc_ai_530_verification',
  earningsRefresh: 'pro_earnings_refresh',
  earningsShiftBreakdown: 'pro_earnings_shift_hours_breakdown',
  findJobsInfiniteScroll: 'find_jobs_infinite_scroll',
  inAppDisputeResolution: 'pro_in_app_dispute_resolution',
  proCardRedesign: 'pro_job_card_redesign',
  proClockOutSupervisorSignature: 'pro_clock_out_supervisor_signature',
  proClockOutSupervisorSignatureOT: 'pro_clock_out_supervisor_signature_ot',
  qualificationAutomationEnabled: 'credential_automation_ui',
  showMileageInJobDetails: 'show_mileage_in_job_details',
  topLevelBookButton: 'top_level_book_button',
  tosOn: 'terms_of_service',
  travelQualificationsImprovements: 'pro_travel_qualifications_improvements',
  proShiftConfirmationExperience: 'lx_ns_check_in_experience',
  proStripeCompanyRep: 'pro_stripe_company_rep',
  splitPay: 'split_pay',
  taxUiUplift: 'pro_tax_ui_uplift',
  chartingReminders: 'fg_charting_reminders',
  xrTransparency: 'pro_xr_transparency',
  roundClockOut: 'pro_round_clock_out',
};

export const experimentNames = {
  ahaEcode: 'aha_ecode',
};

export const valueNames = {
  NEW_CARDS: 'new_cards',
};
