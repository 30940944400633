import {
  Box,
  Heading,
  TextField,
  DateField,
  CheckboxField,
  HStack,
  SSNField,
  VStack,
  SingleSelectField,
  EmailField,
  Link,
} from '@medely/ui-kit';
import { config } from '@mp/config';
import { toPhoneNumber } from '@mp/utils/toPhoneNumber';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  stateOptions: {
    value: string | number;
    label: string;
  }[];
};

export const TaxInfoBusinessLegalRepresentativeInputs = ({ stateOptions }: Props) => {
  const { watch, setValue } = useFormContext();

  const isUseBusinessAddressChecked = watch('representative.use_business_address');
  const taxClassificationId = watch('tax_classification_id');

  const showFullSsn = Number(taxClassificationId) === 2 || Number(taxClassificationId) === 4;

  useEffect(() => {
    setValue('representative.show_full_ssn', showFullSsn);

    if (showFullSsn) {
      setValue('representative.ssn_last_4', null);
    } else {
      setValue('representative.id_number', null);
    }
  }, [showFullSsn]);

  return (
    <VStack gap={2}>
      <HStack gap={1} mt={2} alignItems="center">
        <Heading size="xs" testId="tax-info-legal-representative-title">
          Company representative
        </Heading>
        <Link url={config.help.stripeRepresentativeTaxInfo} variant="text" size="s">
          Find out who qualifies
        </Link>
      </HStack>
      <TextField label="First name" name="representative.first_name" />
      <TextField label="Last name" name="representative.last_name" />
      <TextField label="Job title" name="representative.title" />

      <DateField label="Date of birth" name="representative.dob" />
      {showFullSsn ? (
        <SSNField name={'representative.id_number'} label={'SSN'} helperText="" />
      ) : (
        <SSNField
          name={'representative.ssn_last_4'}
          label={'Last 4 digits of SSN'}
          last4only
          helperText=""
        />
      )}

      <TextField
        label="Phone number"
        name="representative.phone"
        formatOnChange={(value) => toPhoneNumber(value)}
        maxLength={15}
      />
      <EmailField label="Email" name="representative.email" />

      <CheckboxField label="Use business address" name="representative.use_business_address" />

      {!isUseBusinessAddressChecked && (
        <>
          <TextField label="Address 1" name="representative.address_first" />

          <HStack gap={1}>
            <Box flex={1}>
              <TextField label="Address 2" name="representative.address_second" />
            </Box>
            <Box flex={1}>
              <TextField label="City" name="representative.address_city" />
            </Box>
          </HStack>

          <HStack gap={1}>
            <Box flex={1}>
              <SingleSelectField
                options={stateOptions}
                label="State"
                name="representative.state_id"
              />
            </Box>
            <Box flex={1}>
              <TextField label="Zipcode" name="representative.address_zip" />
            </Box>
          </HStack>
        </>
      )}
    </VStack>
  );
};
