import React, { useEffect } from 'react';
import { Box, Heading, MultiSelectField, OptionType, useAnalytics, VStack } from '@medely/ui-kit';
import { PositionsDetailsModalButton } from './PositionsDetailsModalButton';
import { IPosition } from '@medely/types';

import { SelectedPosition } from './types';
import { getRecommendedPositions } from '../../util';
import { useCurrentUser } from '@mp/hooks';

type RecommendedPositionsProps = {
  allPositions: IPosition[];
  initialSubmittedPositions: SelectedPosition[];
};

export const RecommendedPositions = ({
  allPositions,
  initialSubmittedPositions,
}: RecommendedPositionsProps) => {
  const { currentUser } = useCurrentUser();
  const analytics = useAnalytics();
  const [recommendedPositionOptions, setRecommendedPositionOptions] = React.useState<OptionType[]>(
    [],
  );

  useEffect(() => {
    if (!!currentUser) {
      analytics.track('Product Application Related Positions - Viewed', {
        account_id: currentUser?.id,
        context: 'Product Application Related Positions',
        subcontext: null,
        action: 'Viewed',
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (!recommendedPositionOptions.length && allPositions.length) {
      setRecommendedPositionOptions(
        getRecommendedPositions(allPositions, initialSubmittedPositions),
      );
    }
  }, [allPositions]);

  const detailsPositions = allPositions
    .filter((position) => recommendedPositionOptions.some((option) => option.value === position.id))
    .sort((a, b) => a.display_name.localeCompare(b.display_name));

  return (
    <Box>
      <Heading size="s" color="text.primary" testId="recommended-positions-title">
        Select related positions to increase your earnings potential
      </Heading>
      <PositionsDetailsModalButton positions={detailsPositions} />
      <VStack gap={2}>
        <MultiSelectField
          variant="list"
          options={recommendedPositionOptions}
          label="Recommended Positions"
          name="professional_positions.positions"
          testId="recommended-positions-multi-select"
          parseValue={(value) => value.map((position: SelectedPosition) => position.id)}
          formatOnChange={(newValue, fieldValue) => {
            const fieldValueCopy = [...fieldValue];
            newValue.forEach((value: number) => {
              if (!fieldValueCopy.some((position: SelectedPosition) => position.id === value)) {
                fieldValueCopy.push({
                  id: value,
                  years_of_experience: undefined,
                  specialty_ids: [],
                });
              }
            });
            const returnValue = fieldValueCopy.filter(
              (position: SelectedPosition) =>
                newValue.includes(position.id) ||
                initialSubmittedPositions.some((pos) => pos.id === position.id),
            );
            return returnValue;
          }}
        />
      </VStack>
    </Box>
  );
};
