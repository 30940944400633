import { useGraphQLQuery } from './useGraphQLRequest';
import { type ResultOf } from '@graphql-typed-document-node/core';
import { graphql } from '../graphql/generated';

type TaxFormGqlResult = ResultOf<typeof GET_TAX_FORMS>;

export const GET_TAX_FORMS = graphql(`
  query TaxForms($input: TaxFormQueryInput) {
    taxForms(input: $input) {
      id
      year
    }
  }
`);

interface IUseTaxFormReturn {
  taxForms: TaxFormGqlResult['taxForms'];
  isLoading: boolean;
}

const useTaxForms = (): IUseTaxFormReturn => {
  const { data, isLoading } = useGraphQLQuery<TaxFormGqlResult>({
    operationName: 'GetTaxForms',
    query: GET_TAX_FORMS,
    variables: {},
  });

  return { taxForms: data?.taxForms ?? [], isLoading };
};

export default useTaxForms;
