import { Box, Label, Text } from '@medely/ui-kit';
import React from 'react';

export const NoPositionsAndSpecialties = () => {
  return (
    <Box py={4}>
      <Box pb={0.5}>
        <Label size="m" textAlign="center">
          No positions and specialties
        </Label>
      </Box>
      <Text size="m" textAlign="center">
        Positions and specialties help identify shifts that align with your expertise and
        experience.
      </Text>
    </Box>
  );
};
