import { Box, HStack, Icon, Text } from '@medely/ui-kit';
import React from 'react';

type RequiredFacilitySignatureWarningProps = {
  smallIcon?: boolean;
};

export const RequiredFacilitySignatureWarning = ({
  smallIcon = false,
}: RequiredFacilitySignatureWarningProps) => {
  return (
    <HStack alignContent="center" pl={0.5} pt={1}>
      <Icon
        name="solid-info-circle"
        iconSize={smallIcon ? '13px' : '19px'}
        color="text.primary"
        variant="custom"
      />
      <Box pl={2}>
        <Text size="l" color="text.secondary">
          A facility admin signature will be required.
        </Text>
      </Box>
    </HStack>
  );
};
