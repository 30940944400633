import React from 'react';
import moment from 'moment';
import { DxGyFinishCard } from './DxGyFinishCard';
import { DxGyInProgressCard } from './DxGyInProgressCard';
import { DxGyPreStartCard } from './DxGyPreStartCard';
import { centsToCurrency } from '../../../../utils';
import { CampaignDataProps } from '../Interface';

type DxGyCardProps = {
  data: CampaignDataProps;
};

export const DxGyCard = ({ data }: DxGyCardProps) => {
  const today = moment();
  const startShowCards = moment(data.starts_time).subtract(3, 'days');
  const stopShowInProgressCards = moment(data.ends_time).add(1, 'days');
  const stopShowFinishCard = moment(data.ends_time).add(3, 'days');
  const goal = data.goal.per_diem_shifts;
  const progress = data.progress?.per_diem_shifts;
  const completedCampaign = !!goal && !!progress && progress >= goal;
  const isInProgress = !!progress && progress >= 1;
  const startTime = moment(data.starts_time).format('MM/DD');
  const endTime = moment(data.ends_time).format('MM/DD');
  const amountCents = centsToCurrency(data.amount_cents);
  const stringGoal = !!goal ? goal.toString() : '';
  const stringProgress = !!progress ? progress.toString() : '';

  if (completedCampaign && today.isSameOrBefore(stopShowFinishCard, 'day')) {
    return <DxGyFinishCard amount={amountCents} goal={stringGoal} />;
  }
  if (
    today.isSameOrAfter(startShowCards, 'day') &&
    today.isSameOrBefore(stopShowInProgressCards, 'day')
  ) {
    return isInProgress ? (
      <DxGyInProgressCard
        endTime={endTime}
        goal={stringGoal}
        amount={amountCents}
        progress={stringProgress}
      />
    ) : (
      <DxGyPreStartCard
        startTime={startTime}
        endTime={endTime}
        amount={amountCents}
        goal={stringGoal}
      />
    );
  } else {
    return null;
  }
};
