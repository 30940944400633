import React from 'react';
import { Box, Button, Heading, HStack, Icon, Label, Text } from '@medely/ui-kit';
import { FixedFooterLayout } from '@mp/components';

type HasNoSpecialtyInfoProps = {
  handleBackClick: (param: undefined) => void;
  positionName: string;
};

export const HasNoSpecialtyInfo = ({ handleBackClick, positionName }: HasNoSpecialtyInfoProps) => {
  return (
    <FixedFooterLayout
      title={
        <>
          <Box onClick={() => handleBackClick(undefined)}>
            <HStack>
              <Icon
                name="chevron-left"
                color="state.teal.primary"
                variant="custom"
                iconSize="10px"
              />
              <Label size="m" color="state.teal.primary">
                Positions and specialties
              </Label>
            </HStack>
          </Box>
          <Heading size="m" color="text.primary">
            {positionName}
          </Heading>
        </>
      }
      footerContent={
        <Button onClick={() => handleBackClick(undefined)} fullWidth>
          Back
        </Button>
      }
    >
      <Box pt={10}>
        <Text size="l" color="text.primary">
          For now, this Position does not have any Specialties to be selected.
        </Text>
      </Box>
    </FixedFooterLayout>
  );
};
