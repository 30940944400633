import { useCurrentUser } from '@mp/hooks';
import {
  isAgencyAccount,
  isSaasCredentialingAccount,
  isSaasCredentialingAccountInput,
  isSaasCredentialingAndSchedulingAccount,
  isSaasCredentialingAndSchedulingAccountInput,
} from '@mp/utils';
import React, { PropsWithChildren, ReactElement } from 'react';

type SaasType = 'credentialing' | 'scheduling';

type HideFromProps = PropsWithChildren<{
  saas?: boolean;
  agency?: boolean;
  saasTypes?: SaasType[];
  fallback?: ReactElement;
}>;

export const HideFrom = ({
  saas,
  agency,
  saasTypes = ['credentialing', 'scheduling'],
  children,
  fallback,
}: HideFromProps) => {
  const { currentUser } = useCurrentUser();

  const isCredentialingAndScheduling = isSaasCredentialingAndSchedulingAccount(
    currentUser as isSaasCredentialingAndSchedulingAccountInput,
  );
  const isCredentialing = isSaasCredentialingAccount(
    currentUser as isSaasCredentialingAccountInput,
  );
  const isAgency = isAgencyAccount(currentUser);

  if (saas) {
    if (isCredentialingAndScheduling || isCredentialing) {
      const saasType: SaasType = isCredentialingAndScheduling ? 'scheduling' : 'credentialing';
      if (saasTypes.includes(saasType)) {
        return fallback ?? null;
      }
    }
  }

  if (agency) {
    if (isAgency) {
      return fallback ?? null;
    }
  }

  return <>{children}</>;
};
