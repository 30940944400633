import React from 'react';
import { TaskCardLayout } from '../TaskCard';
import { TaskCard } from '@medely/ui-kit';
import { useProNavigate } from '../../../contexts/NavigationContext';
import moment from 'moment';
import { CampaignDataProps } from './Interface';

type SpecialtiesCardProps = {
  specialCardInfo: CampaignDataProps;
};

const CardLayout = () => {
  const { navigate } = useProNavigate();

  return (
    <TaskCardLayout>
      <TaskCard
        iconProps={{ name: 'award', color: 'success' }}
        title="Update specialities"
        body="To improve job offerings, update your profile with any specialities you have experience in."
        onClick={() => navigate('PositionsAndSpecialties')}
      />
    </TaskCardLayout>
  );
};

export const SpecialtiesCard = ({ specialCardInfo }: SpecialtiesCardProps) => {
  const today = moment();
  const goal = specialCardInfo.goal.viewed_specialties;
  const progress = specialCardInfo.progress.viewed_specialties;
  const startShowCards = moment(specialCardInfo.starts_time).subtract(3, 'days');
  const stopShowFinishCard = moment(specialCardInfo.ends_time).add(3, 'days');
  const completedCampaign =
    typeof progress === 'number' && typeof goal === 'number' ? progress >= goal : true;

  if (
    !completedCampaign &&
    today.isSameOrBefore(stopShowFinishCard, 'day') &&
    today.isSameOrAfter(startShowCards, 'day')
  ) {
    return <CardLayout />;
  } else {
    return null;
  }
};
