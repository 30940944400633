import React from 'react';
import { Box, InfoBadge, InfoBadgeColor, TaskCard } from '@medely/ui-kit';
import { useProNavigate } from '../../contexts/NavigationContext';
import { TaskCardLayout } from './TaskCard';

type ExpiringConditionsProps = {
  title: string;
  severity: InfoBadgeColor;
};

export const ExpiringConditions = ({ title, severity }: ExpiringConditionsProps) => {
  const { navigate } = useProNavigate();
  const handleClick = () => navigate('MissingAndExpiringConditions');
  return (
    <TaskCardLayout>
      <TaskCard
        iconProps={{ name: 'exclamation-triangle', color: severity }}
        title={title}
        body={
          <Box alignSelf="flex-start">
            <InfoBadge label="Needs Attention" color={severity} />
          </Box>
        }
        onClick={handleClick}
      />
    </TaskCardLayout>
  );
};
