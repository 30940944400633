import React, { ReactElement } from 'react';
import { Box, Container } from '@medely/web-components';
import { FixedTitleProps } from './interface';
import useDevice from '@mp/hooks/useDevice.web';

export const FixedTitle = ({ children }: FixedTitleProps): ReactElement => {
  const isMobile = useDevice() === 'mobile';

  return (
    <Box
      mt={isMobile ? 0 : 5}
      position="fixed"
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      left={isMobile ? 0 : 20}
      right={isMobile ? 0 : 20}
      top={isMobile ? 59 : 35}
      p={2}
      bgcolor={'background.paper'}
      sx={{ overflowY: 'hidden' }}
      zIndex={11}
    >
      <Container maxWidth="sm">{children}</Container>
    </Box>
  );
};
