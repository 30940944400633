import React from 'react';
import ErrorAlert from '../errorAlert/ErrorAlert';

import { Icon, Text } from '@medely/ui-kit';
import { HelpCenterLink } from '../helpCenterLink';

export const UnableToVerify530Form = () => {
  return (
    <ErrorAlert
      icon={
        <Icon
          name="solid-circle-exclamation"
          iconSize="18px"
          color="state.error.secondary"
          variant="custom"
        />
      }
      title="Unable to verify form, please retry"
      body={
        <Text size="m" color="state.warning.primary">
          Ensure you&apos;re submitting a{' '}
          <HelpCenterLink text="valid 530 form" configKey="formFaq" />. Retake the photo in a
          well-lit area and make sure the entire sheet is visible.
        </Text>
      }
    />
  );
};

export const ErrorVerifyingYour530form = () => {
  return (
    <ErrorAlert
      icon={
        <Icon
          name="solid-circle-exclamation"
          iconSize="18px"
          color="state.error.secondary"
          variant="custom"
        />
      }
      title="Error verifying your 530 form"
      body={
        <Text size="m" color="state.warning.primary">
          We&apos;re not able to verify this 530 form. Ensure you&apos; followed the{' '}
          <HelpCenterLink configKey="formFaq" text="guidance" /> and then submit your timesheet.
          Invalid forms can delay payouts
        </Text>
      }
    />
  );
};

export const ErrorFileIsTooLarge = () => {
  return (
    <ErrorAlert
      icon={
        <Icon
          name="solid-circle-exclamation"
          iconSize="18px"
          color="state.error.secondary"
          variant="custom"
        />
      }
      title="File size limit"
      body={
        <Text size="m" color="state.warning.primary">
          The file that you are trying to use is too large. The file size limit is 20 MB.
        </Text>
      }
    />
  );
};
