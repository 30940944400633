import { getOTRateLabel } from '@medely/base';
import { Text, Box, Heading } from '@medely/ui-kit';
import { Divider } from '@medely/web-components';
import React from 'react';
import { useGate } from 'statsig-react';
import { WhatToExpectInfo } from '../whatToExpectModal/WhatToExpectInfo.web';
import { CancelationPolicy } from '../jobs/CancelationPolicy.web';
import { JobDetailsListItem } from '../jobs/JobDetailsListItem';
import {
  Calendar,
  CalendarClock,
  Clock,
  InfoCircle,
  MapMarkerAlt,
} from '@medely/web-components/icons';
import { EstimatedPayDisclaimer, ExtendedRateInfo, ShiftDate, ShiftLocation } from '../jobs';
import DisplayHelpers from '../../utils/displayHelpers';
import { PostBookingInfoProps } from './interface';
import { gateNames } from '../../constants';
import { HideFrom } from '../HideFrom';

export const PostBookingInfo = ({
  jobMap,
  job,
  cancelationPolicyButton,
  userCoordinates,
}: PostBookingInfoProps) => {
  const { value: showXRInfo } = useGate(gateNames.xrTransparency);

  return (
    <Box px={1}>
      <Text size="l" color="text.primary">
        You are now scheduled to work a shift at {job.location.name}.
      </Text>
      <Box mt={2}>{jobMap}</Box>
      <Box py={2}>
        <Box py={1}>
          <JobDetailsListItem icon={<MapMarkerAlt width="14px" />} title="Location">
            <ShiftLocation
              bookVariant="post-book"
              distanceVariant="local"
              job={job}
              userCoordinates={userCoordinates}
            />
          </JobDetailsListItem>
        </Box>
        <Box py={1}>
          <JobDetailsListItem icon={<Calendar width="14px" />} title="Date">
            <ShiftDate job={job} />
          </JobDetailsListItem>
        </Box>
        <Box py={1}>
          <JobDetailsListItem icon={<Clock width="14px" />} title="Shift time">
            <Text size="m">{DisplayHelpers.job.time(job, { scheduledShiftStyle: true })}</Text>
          </JobDetailsListItem>
        </Box>
        {showXRInfo && (
          <Box py={1}>
            <JobDetailsListItem
              icon={<CalendarClock width="14px" />}
              title={`${getOTRateLabel({ isW2: job.is_w2, abbreviate: false })}s`}
            >
              <ExtendedRateInfo job={job} />
            </JobDetailsListItem>
          </Box>
        )}
        <HideFrom saas>
          <Box py={1}>
            <JobDetailsListItem icon={<InfoCircle width="14px" />}>
              <EstimatedPayDisclaimer />
            </JobDetailsListItem>
          </Box>
        </HideFrom>
      </Box>
      <Divider />
      <Box py={2}>
        <HideFrom saas>
          <CancelationPolicy />
        </HideFrom>
        <Box width="45%">{cancelationPolicyButton}</Box>
      </Box>
      <HideFrom saas>
        <Divider />
        <Box py={2}>
          <Heading size="xs" color="text.primary">
            What to expect
          </Heading>
        </Box>
        <WhatToExpectInfo />
      </HideFrom>
    </Box>
  );
};
