import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNetworkRequest } from './useNetworkRequest';
import L from 'lodash';
import type { IStripeAccount } from '@medely/types';
import { INDIVIDUAL_TAX_CLASSIFICATION_ID } from '@mp/constants/taxes';

export const useEditStripeAccount = () => {
  const { axios } = useNetworkRequest();
  const queryClient = useQueryClient();

  const {
    mutateAsync: editAsync,
    mutate: edit,
    isLoading: isEditing,
  } = useMutation(
    (payload: Partial<IStripeAccount>) => {
      const isIndividual = payload.tax_classification_id === INDIVIDUAL_TAX_CLASSIFICATION_ID;
      const individualParams = ['first_name', 'last_name'];
      const companyParams = ['company_name', 'representative'];

      return axios.put(`/v3/professional/stripe_accounts/${payload.id}`, {
        stripe_account: L.omit(payload, [
          'external_accounts',
          'state',
          'id',
          ...(isIndividual ? companyParams : individualParams),
        ]),
      });
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['currentUser']);
      },
    },
  );

  return { edit, editAsync, isEditing };
};
