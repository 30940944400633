import { UseMutateFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNetworkRequest } from '../../../../../hooks/useNetworkRequest';
import { AxiosResponse } from 'axios';
import { ExperienceSummaryResumeModalProps } from './types';

const useResumeSubmitMutation = (
  onClose: ExperienceSummaryResumeModalProps['onClose'],
): {
  submitResume: UseMutateFunction<AxiosResponse<any>, any, any, unknown>;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { axios } = useNetworkRequest();

  const { mutateAsync: submitResume, isLoading } = useMutation(
    (values: { resume: File | undefined }) => {
      const file = values.resume;
      if (!file) {
        onClose();
      }
      const formData = new FormData();
      if (file) {
        formData.append('professional[resume]', file);
      }
      return axios.post(`/v3/professional/resume`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['currentUser']);
      },
      onSuccess: () => {
        onClose();
      },
    },
  );

  return {
    submitResume,
    isLoading,
  };
};

export default useResumeSubmitMutation;
