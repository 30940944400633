import { graphql } from '../graphql/generated';
import { useGraphQLQuery } from './useGraphQLRequest';
import { type ResultOf } from '@graphql-typed-document-node/core';

const ProfessionalCohortGql = graphql(`
  query GetProfessionalCohort {
    me {
      professional {
        cohort
      }
    }
  }
`);

type ProfessionalCohort = ResultOf<typeof ProfessionalCohortGql>;

export type UseProfessionalCohortOptions = {
  professional_id?: number;
};

export type UseProfessionalCohortResult = {
  isLoading: boolean;
  professionalCohort: string | null | undefined;
};

export const useProfessionalCohort = (
  options: UseProfessionalCohortOptions,
): UseProfessionalCohortResult => {
  const { professional_id } = options;
  const enabled = !!professional_id;

  const { data, isLoading, isInitialLoading } = useGraphQLQuery<ProfessionalCohort>({
    operationName: 'GetProfessionalCohort',
    query: ProfessionalCohortGql,
    enabled,
    variables: {
      professionalId: professional_id,
    },
  });

  return {
    isLoading: enabled ? isLoading : isInitialLoading,
    professionalCohort: data?.me && data?.me?.professional?.cohort,
  };
};
