import { Text, Box, Heading, InfoBadge } from '@medely/ui-kit';
import React from 'react';
import { Calendar, Clock, MapMarkerAlt } from '@medely/web-components/icons';
import { JobDetailsListItem, ShiftDate, ShiftLocation } from '../../jobs';
import DisplayHelpers from '../../../utils/displayHelpers';
import { ShiftConfirmationInfoProps } from './interface';
import { MedelyDateTime } from '@medely/date-time-tools';
import { pluralize } from '@mp/utils';

export const ShiftConfirmationInfo = ({
  jobMap,
  job,
  userCoordinates,
}: ShiftConfirmationInfoProps) => {
  const startsIn = MedelyDateTime.now({ tz: 'UTC' }).difference(
    new MedelyDateTime(job.current_starts_time, { tz: 'UTC' }),
    'hours',
  );

  const showStartsInBadge = startsIn < 13 && startsIn > 0;

  return (
    <Box px={1}>
      <Box mt={2}>{jobMap}</Box>
      <Box py={2}>
        <Box py={1}>
          <JobDetailsListItem icon={<MapMarkerAlt width="14px" />} title="Location">
            <ShiftLocation
              bookVariant="post-book"
              distanceVariant="local"
              job={job}
              userCoordinates={userCoordinates}
            />
          </JobDetailsListItem>
        </Box>
        <Box py={1}>
          <JobDetailsListItem icon={<Calendar width="14px" />} title="Date">
            <ShiftDate job={job} />
          </JobDetailsListItem>
        </Box>
        <Box py={1}>
          <JobDetailsListItem icon={<Clock width="14px" />} title="Shift time">
            <Text size="m">{DisplayHelpers.job.time(job, { scheduledShiftStyle: true })}</Text>
            {showStartsInBadge && (
              <InfoBadge
                color="info"
                label={`Starts in ${startsIn} ${pluralize(startsIn, 'hour')}`}
              />
            )}
          </JobDetailsListItem>
        </Box>
      </Box>
      {!!job?.detail && (
        <Box py={1}>
          <Heading size="s">Additional details</Heading>
          <Box pt={1.5}>
            <Text size="m">{job?.detail}</Text>
          </Box>
        </Box>
      )}
    </Box>
  );
};
