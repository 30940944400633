import { Box, Button, HStack, StripeFormFields } from '@medely/ui-kit';
import { useStripeCard } from '@medely/web-components/hooks';
import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { FixedFooterLayout } from '../FixedFooterLayout';
import { useProNavigate } from '../../contexts';
import usePaymentInfo from '@mp/hooks/usePaymentInfo';
import type { DebitCardFormProps, DebitCardFormSubmitData } from './interface';
import * as yup from 'yup';

function ButtonActions({ onError, onSuccess }: DebitCardFormProps) {
  const { navigate } = useProNavigate();
  const { handleSubmit, setError } = useFormContext();
  const { authorize } = useStripeCard();
  const { addCard, isAddDirectDepositLoading, isAddCardLoading } = usePaymentInfo();

  const schema = yup.object().shape({
    debit_card_number: yup.mixed().required('The field is required'),
    cvc: yup.mixed().required('The field is required'),
    expiration_date: yup.mixed().required('The field is required'),
  });

  async function validateErrors(data: DebitCardFormSubmitData) {
    try {
      await schema.validate(data, { abortEarly: false });
    } catch (validationErrors) {
      if (validationErrors instanceof yup.ValidationError) {
        validationErrors.inner.forEach((error) => {
          if (error.path) {
            setError(error.path, { message: error.message, type: error.type });
          }
        });
      }
    }
  }

  async function onSubmit(data: DebitCardFormSubmitData & { stripe_token?: string }) {
    validateErrors(data);

    const token = await authorize();
    if (token?.id) {
      data.stripe_token = token.id;
      addCard(data, {
        onError,
        onSuccess,
      });
    }
  }

  function onCancel() {
    navigate('PayoutTaxes');
  }

  return (
    <HStack gap={1}>
      <Box width={'50%'}>
        <Button variant="outlined" fullWidth onClick={onCancel}>
          Cancel
        </Button>
      </Box>
      <Box width={'50%'}>
        <Button
          fullWidth
          onClick={() => handleSubmit((values) => onSubmit(values as DebitCardFormSubmitData))()}
          loading={isAddDirectDepositLoading || isAddCardLoading}
        >
          Save
        </Button>
      </Box>
    </HStack>
  );
}

export const DebitCardForm = ({ onError, onSuccess }: DebitCardFormProps) => {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <FixedFooterLayout
        footerContent={<ButtonActions onError={onError} onSuccess={onSuccess} />}
        height="92%"
      >
        <StripeFormFields />
      </FixedFooterLayout>
    </FormProvider>
  );
};
