import React, { useEffect, useRef, useState } from 'react';
import { FixedFooterLayoutProps } from './interface';
import { FixedFooter } from './InternalFixedFooter.web';
import { FixedTitle } from './InternalFixedTitle.web';

export const FixedFooterLayout = ({ children, footerContent, title }: FixedFooterLayoutProps) => {
  const { height, ref } = useResponsiveFooterSpacer();

  return (
    <>
      {title && <FixedTitle>{title}</FixedTitle>}
      <div style={{ paddingBottom: `${height}px` }}>{children}</div>
      <FixedFooter ref={ref}>{footerContent}</FixedFooter>
    </>
  );
};

const useResponsiveFooterSpacer = (): {
  height: number;
  ref: React.MutableRefObject<any>;
} => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target.clientHeight !== height) {
          setHeight(entry.target.clientHeight);
        }
      }
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return { height, ref };
};
