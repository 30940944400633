export const pageTitles = {
  default: 'Medely | The #1 Digital Network for Nurses and Allied Professionals',
  application: 'Application - Medely | The #1 Digital Network for Nurses and Allied Professionals',
  today: 'Today - Medely',
  findJobs: 'Find jobs - Medely',
  myShifts: 'My shifts - Medely',
  myAppliedAssignments: 'My assignment applications - Medely',
  myBookedAssignments: 'My booked assignments - Medely',
  myFavoriteAssignments: 'Favorites - Medely',
  timesheets: 'Timesheets - Medely',
  earnings: 'Earnings - Medely',
  profile: 'Profile - Medely',
  settings: 'Settings - Medely',
  payoutsAndTaxes: 'Payouts & taxes - Medely',
  personalInfo: 'Personal information - Medely',
  resume: 'Experience - Medely',
  credentials: 'Credentials - Medely',
  skillsAssessments: 'Skills assessments - Medely',
  references: 'References - Medely',
  positionsAndSpecialties: 'Positions and Specialties - Medely',
};

export const formatPageTitleWithId = (
  page: 'assignmentDetails' | 'shiftDetails' | 'payoutDetails',
  id: number,
) => {
  switch (page) {
    case 'assignmentDetails':
      return `Assignment ${id ? `${id} ` : ``}- Medely`;
    case 'shiftDetails':
      return `Shift ${id ? `${id} ` : ``}- Medely`;
    case 'payoutDetails':
      return `Payout ${id ? `${id} ` : ``} - Medely`;
  }
};
