import { UseMutateFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNetworkRequest } from './useNetworkRequest';
import { AxiosResponse } from 'axios';

const useSpecialtiesMutations = (): {
  submitSpecialtiesInfo: UseMutateFunction<AxiosResponse<any>, any, any, unknown>;
  trackViewedSpecialties: UseMutateFunction<void, unknown, void, unknown>;
  isSpecialtiesLoading: boolean;
} => {
  const { axios } = useNetworkRequest();
  const queryClient = useQueryClient();

  const { mutate: submitSpecialtiesInfo, isLoading } = useMutation(
    (values) => axios.post(`/v3/professional/specialties`, values),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['currentUser']);
        queryClient.invalidateQueries(['ProfilePositionsWithSpecialties']);
      },
    },
  );

  const { mutate: trackViewedSpecialties } = useMutation({
    mutationFn: async () => {
      await axios.post('/v3/professional/actions', {
        actions: { action: 'viewed_specialties' },
      });
    },
    onSuccess: () => {
      // This is used in the current environment, remove when merged with pro-pool-integration
      queryClient.invalidateQueries(['todos']);
      // This is used in the staging environment only with pro-pool-integration
      queryClient.invalidateQueries(['GetProfessionalTodos']);
    },
  });

  return { submitSpecialtiesInfo, isSpecialtiesLoading: isLoading, trackViewedSpecialties };
};

export default useSpecialtiesMutations;
