import { useAnalytics } from '@medely/ui-kit';
import { useCurrentUser } from '../useCurrentUser';

export function useClockInOutEvent() {
  const analytics = useAnalytics();
  const { currentUser } = useCurrentUser();
  const professionalId = currentUser?.professional?.id?.toString();

  const trackStartShiftInJobDetails = (jobId: number) => {
    analytics.track('Job Details - Start Shift Button - Clicked', {
      context: 'Job Details',
      subcontext: 'Start Shift Button',
      action: 'Clicked',
      professional_id: professionalId,
      job_id: jobId,
      impersonating: false,
    });
  };

  const trackStartShiftInToday = (jobId: number) => {
    analytics.track('Today - Start Shift Button - Clicked', {
      context: 'Today',
      subcontext: 'Start Shift Button',
      action: 'Clicked',
      professional_id: professionalId,
      job_id: jobId,
      impersonating: false,
    });
  };

  const trackShareLocationTakePhotoNextButton = (jobId: number) => {
    analytics.track('Share Location Take Photo - Continue Button - Clicked', {
      context: 'Share Location Take Photo',
      subcontext: 'Continue Button',
      action: 'Clicked',
      professional_id: professionalId,
      job_id: jobId,
      impersonating: false,
    });
  };

  const trackStartShiftConfirm = (jobId: number) => {
    analytics.track('Start Shift - Confirm Button - Clicked', {
      context: 'Start Shift',
      subcontext: 'Confirm Button',
      action: 'Clicked',
      professional_id: professionalId,
      job_id: jobId,
      impersonating: false,
    });
  };

  const trackEndShiftInJobDetails = (jobId: number) => {
    analytics.track('Job Details - End Shift Button - Clicked', {
      context: 'Job Details',
      subcontext: 'End Shift Button',
      action: 'Clicked',
      professional_id: professionalId,
      job_id: jobId,
      impersonating: false,
    });
  };

  const trackEndShiftInToday = (jobId: number) => {
    analytics.track('Today - End Shift Button - Clicked', {
      context: 'Today',
      subcontext: 'End Shift Button',
      action: 'Clicked',
      professional_id: professionalId,
      job_id: jobId,
      impersonating: false,
    });
  };

  const trackTimesheetNextButton = (jobId: number) => {
    analytics.track('Timesheet Requirement - Next Button - Clicked', {
      context: 'Timesheet Requirement',
      subcontext: 'Next Button',
      action: 'Clicked',
      professional_id: professionalId,
      job_id: jobId,
      impersonating: false,
    });
  };

  // The endShiftConfirm event is located on trackClockOut

  return {
    trackStartShiftInJobDetails,
    trackStartShiftInToday,
    trackShareLocationTakePhotoNextButton,
    trackStartShiftConfirm,
    trackEndShiftInJobDetails,
    trackEndShiftInToday,
    trackTimesheetNextButton,
  };
}
