import React, { useEffect, useRef, useState } from 'react';
import { Box, Heading } from '@medely/ui-kit';
import { ContentLoader } from '../../../components';
import { useCurrentUser, usePositionsWithSpecialties } from '../../../hooks';
import { NoPositionsAndSpecialties } from './components/NoPositionsAndSpecialties';
import { PositionProps } from './interface';
import { PositionAndSpecialtyItem } from './components/PositionAndSpecialtyItem';
import { formattedSpecialties } from '../utils';
import { SpecialtiesView } from './SpecialtiesView';
import useSpecialtiesMutations from '@mp/hooks/useSpecialtiesMutations';
import { HasNoSpecialtyInfo } from './components/HasNoSpecialtyView';

type PositionsAndSpecialtiesViewProps = {
  showTitle?: boolean;
  onPageChange?: (params: boolean) => void;
};

export const PositionsAndSpecialtiesView = ({
  showTitle = false,
  onPageChange,
}: PositionsAndSpecialtiesViewProps) => {
  const hasPosted = useRef(false);
  const { trackViewedSpecialties } = useSpecialtiesMutations();
  const [selectedPosition, setSelectedPosition] = useState<undefined | number>();
  const { currentUser, isLoading: loadingUser } = useCurrentUser();
  const professionalPositions = currentUser?.professional?.positions ?? [];
  const professionalPositionIds = professionalPositions.map(({ id }) => id);
  const { positions: allPositionsWithSpecialties, isLoading: loadingPositions } =
    usePositionsWithSpecialties({
      positionIds: professionalPositionIds,
    });
  const professionalSpecialties = currentUser?.professional?.specialties;

  const formattedPositions: PositionProps[] = professionalPositions.map((position) => ({
    header: position.display_name ?? '',
    description: formattedSpecialties(
      position.id,
      allPositionsWithSpecialties,
      professionalSpecialties,
    ),
    id: position.id,
  }));

  useEffect(() => {
    if (hasPosted.current) {
      return;
    }
    hasPosted.current = true;
    trackViewedSpecialties();
  }, [trackViewedSpecialties]);

  const handleUpdatePositionClick = (positionId: number) => {
    setSelectedPosition(positionId);
  };

  // We call this every render to ensuer the header is shown/hidden accurately
  // Normally we'd place this logic in the handlers themselves, however the handler
  // is prop drilled far enough that it adds unexpected complexity, in which this
  // solution works better then the handlers.
  // TODO(PRO-3729): Look at refactor opportunities
  onPageChange && onPageChange(true);
  if (!!selectedPosition) {
    onPageChange && onPageChange(false);
    const positionWithSpecialtiesInfo = allPositionsWithSpecialties.find(
      (position) => position.id === selectedPosition,
    );
    const hasSpecialties = !!positionWithSpecialtiesInfo?.specialties;

    return (
      <ContentLoader loading={loadingUser || loadingPositions}>
        {hasSpecialties ? (
          <SpecialtiesView
            positionWithSpecialtiesInfo={positionWithSpecialtiesInfo}
            professionalSpecialties={professionalSpecialties}
            onBackClick={setSelectedPosition}
          />
        ) : (
          <HasNoSpecialtyInfo
            handleBackClick={setSelectedPosition}
            positionName={positionWithSpecialtiesInfo?.display_name || ''}
          />
        )}
      </ContentLoader>
    );
  }

  return (
    <ContentLoader loading={loadingUser || loadingPositions}>
      <>
        {showTitle && (
          <Heading size="m" color="text.primary">
            Positions and specialties
          </Heading>
        )}
        {!!formattedPositions.length ? (
          <Box>
            {formattedPositions.map(({ header, description, id }) => (
              <Box key={`position-${id}`} onClick={() => handleUpdatePositionClick(id)}>
                <PositionAndSpecialtyItem header={header} description={description} id={id} />
              </Box>
            ))}
          </Box>
        ) : (
          <NoPositionsAndSpecialties />
        )}
      </>
    </ContentLoader>
  );
};
