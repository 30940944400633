import { IAccount, ICompany } from '@medely/types';

type isSaasAccountInput = Pick<IAccount, 'company'>;
export const isSaasAccount = (account: isSaasAccountInput) => Boolean(account.company);

export type isSaasCredentialingAndSchedulingAccountInput = isSaasAccountInput & {
  company: Pick<ICompany, 'saas_scheduling_enabled'>;
};
export const isSaasCredentialingAndSchedulingAccount = (
  account: isSaasCredentialingAndSchedulingAccountInput,
) => isSaasAccount(account) && Boolean(account.company?.saas_scheduling_enabled);

export type isSaasCredentialingAccountInput = isSaasAccountInput &
  isSaasCredentialingAndSchedulingAccountInput & {
    company: Pick<ICompany, 'saas_credentialing_enabled'>;
  };
export const isSaasCredentialingAccount = (account: isSaasCredentialingAccountInput) =>
  isSaasAccount(account) &&
  !isSaasCredentialingAndSchedulingAccount(account) &&
  Boolean(account.company?.saas_credentialing_enabled);

export type IsAgencyAccountInput = Pick<IAccount, 'agency_id'> | undefined;
export const isAgencyAccount = (account?: IsAgencyAccountInput) => Boolean(account?.agency_id);

export type isMarketplaceAccountInput = Pick<IAccount, 'company' | 'agency_id'>;
export const isMarketplaceAccount = (account: isMarketplaceAccountInput) =>
  !isAgencyAccount(account) && !isSaasAccount(account);
