import { Label, Text, VStack, HStack } from '@medely/ui-kit';
import React, { ReactNode } from 'react';

type ErrorAlertProps = {
  icon: ReactNode;
  title: string;
  body: string | ReactNode;
};

const ErrorAlert = ({ icon, title, body }: ErrorAlertProps) => {
  return (
    <VStack bgColor="state.error.backing-1" borderRadius="8px">
      <HStack p={1.5}>
        <VStack p={0.5} mr={1}>
          {icon}
        </VStack>
        <VStack flex={1}>
          <HStack my={0.5}>
            <Label size="m" color="state.error.primary">
              {title}
            </Label>
          </HStack>

          {typeof body === 'string' ? (
            <Text size="m" color="state.warning.primary">
              {body}
            </Text>
          ) : (
            body
          )}
        </VStack>
      </HStack>
    </VStack>
  );
};

export default ErrorAlert;
