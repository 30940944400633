import React from 'react';
import type { IExternalAccount, IStripeAccount } from '@medely/types';
import { Box, Heading, InfoBadge, VStack, Text, Button, Label } from '@medely/ui-kit';
import { useProNavigate } from '@mp/contexts';
import { useEditStripeAccount } from '@mp/hooks/useStripeAccount';

type DirectDepositSectionProps = {
  account: IStripeAccount;
};

type WithDataVariationProps = {
  active: boolean;
  navigateToPayoutMethod: (withAccount: boolean) => void;
  handleClickSetAsActive: VoidFunction;
  isEditing: boolean;
} & DirectDepositSectionProps;

type EmptyStateVariationProps = {
  handleClickSetAsActive: VoidFunction;
  isLoading: boolean;
};

function EmptyStateVariation({ handleClickSetAsActive, isLoading }: EmptyStateVariationProps) {
  return (
    <VStack mt={1}>
      <Text size="s">Activate your account to set up a direct deposit method.</Text>
      <Box mt={1}>
        <Button onClick={handleClickSetAsActive} fullWidth loading={isLoading}>
          Set as active
        </Button>
      </Box>
    </VStack>
  );
}

function WithDataVariation({
  active,
  navigateToPayoutMethod,
  account,
  handleClickSetAsActive,
  isEditing,
}: WithDataVariationProps) {
  const externalAccount = account.external_accounts?.[0] as IExternalAccount;

  function getAccountType(type: string) {
    if (type === 'card') {
      return 'Debit card ';
    }

    return 'Bank account ';
  }

  function handleClickUpdateDirectDeposit() {
    navigateToPayoutMethod(true);
  }

  return (
    <Box>
      {active && (
        <Box mt={1}>
          <Box width={140} mb={0.5}>
            <InfoBadge color="success" label="Active payout account" />
          </Box>

          <Box mt={0.5}>
            <Text size="s">The active payout account receives all payouts.</Text>
          </Box>
        </Box>
      )}

      {externalAccount && (
        <VStack mb={1} mt={active ? 2 : 1}>
          <Label size="s">
            {getAccountType(externalAccount.account_type)}
            ending in {externalAccount.last4}
          </Label>

          {externalAccount.exp_month && (
            <Text size="s">
              Expires {externalAccount.exp_month}/{externalAccount.exp_year}
            </Text>
          )}
        </VStack>
      )}

      {active ? (
        <Box mt={1}>
          <Button variant="outlined" fullWidth onClick={handleClickUpdateDirectDeposit}>
            Update direct deposit
          </Button>
        </Box>
      ) : null}
      {!active && (
        <Box mt={2}>
          <Text size="s">
            {!externalAccount?.id
              ? 'The active payout account receives all payouts.'
              : 'The active payout account receives all payouts. Account details can be edited for active accounts.'}
          </Text>
          <Box mt={1}>
            <Button
              variant="outlined"
              fullWidth
              onClick={handleClickSetAsActive}
              loading={isEditing}
            >
              Set as active
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export const DirectDepositSection = ({ account }: DirectDepositSectionProps) => {
  const externalAccount = account.external_accounts?.[0];
  const { navigate } = useProNavigate();
  const { edit, isEditing } = useEditStripeAccount();
  const { active } = account;

  if (!account) {
    navigate('PayoutTaxes');
  }

  function getVariation() {
    if (!externalAccount && !active) {
      return 'empty-state';
    }

    if (!active) {
      return 'inactive';
    }

    return 'active';
  }

  const variation = getVariation();

  function navigateToPayoutMethod(withAccount: boolean) {
    navigate('PayoutMethod', withAccount ? { id: account.id } : {});
  }

  function handleClickSetAsActive() {
    edit({
      id: account.id,
      active: true,
    });
  }

  const variationComponent = {
    'empty-state': (
      <EmptyStateVariation handleClickSetAsActive={handleClickSetAsActive} isLoading={isEditing} />
    ),
    inactive: (
      <WithDataVariation
        active={Boolean(active)}
        navigateToPayoutMethod={navigateToPayoutMethod}
        account={account}
        handleClickSetAsActive={handleClickSetAsActive}
        isEditing={isEditing}
      />
    ),
    active: (
      <WithDataVariation
        active={Boolean(active)}
        navigateToPayoutMethod={navigateToPayoutMethod}
        account={account}
        handleClickSetAsActive={handleClickSetAsActive}
        isEditing={isEditing}
      />
    ),
  };

  return (
    <VStack mt={3}>
      <Heading size="s">Direct deposit</Heading>
      {variationComponent[variation]}
    </VStack>
  );
};
