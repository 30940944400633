import { Box, HStack, Label, VStack, Text, Icon } from '@medely/ui-kit';
import { Divider } from '../../../../components/divider/Divider';
import React from 'react';
import { PositionProps } from '../interface';

export const PositionAndSpecialtyItem = (position: PositionProps) => {
  return (
    <>
      <HStack justifyContent="space-evenly" alignItems="center" py={2}>
        <VStack width="95%">
          <Label size="m" color="text.primary">
            {position.header}
          </Label>
          <Box py={!!position.description ? 0.5 : 0}>
            <Text size="m" color="text.secondary">
              {position.description}
            </Text>
          </Box>
        </VStack>
        <Box width="5%">
          <Icon name="chevron-right" />
        </Box>
      </HStack>
      <Divider />
    </>
  );
};
