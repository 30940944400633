export const formatBoolean = (content: string) => {
  return content === 't' ? 'Yes' : 'No';
};

export const formatContent = (content: string) => {
  try {
    const contentArray = JSON.parse(content);
    return Array.isArray(contentArray) ? contentArray.join(', ') : content;
  } catch (e) {
    return content;
  }
};

export const formatContentBasedOnDescription = (description: string, content: string) => {
  return description === 'boolean' ? formatBoolean(content) : formatContent(content);
};
