import { type Routes } from '@medely/ui-kit';

// Key = Route name
// Value = Params type
// Optional params should use | undefined, not ?
// Currently only primitive values are supported.
// All params are query params (no path params)
export type ProRoutes = {
  AdjustmentDetail: {
    id: number;
  };
  Application: never;
  AvailableAssignmentsLocal: never;
  EarningsPayoutDetails: {
    id: number;
  };
  EarningsPending: never;
  JobDetails: {
    id: number;
  };
  Profile: never;
  PositionsAndSpecialties: never;
  PayoutTaxes: never;
  AccountDetails: { id: number | string };
  BackgroundCheckDisclosure: never;
  PayoutMethod: Partial<{ id: number | string }>;
  TaxInformation: Partial<{ id: number | string }>;
  MissingAndExpiringConditions: never;
};

export const proRoutes: Routes<ProRoutes> = {
  AdjustmentDetail: {
    url: '/earnings/adjustments/:id',
  },
  Application: {
    url: '/application',
  },
  AvailableAssignmentsLocal: {
    url: '/available/assignments/local',
  },
  EarningsPayoutDetails: {
    url: '/earnings/payouts/:id',
  },
  EarningsPending: {
    url: '/earnings/pending',
  },
  JobDetails: {
    url: '/jobs/:id',
  },
  Profile: {
    url: '/profile',
  },
  PositionsAndSpecialties: {
    url: '/profile/positions-and-specialties',
  },
  PayoutTaxes: {
    url: '/settings/financial',
  },
  AccountDetails: {
    url: '/settings/financial/:id/details',
  },
  BackgroundCheckDisclosure: {
    url: '/onboarding/background_check/disclosure',
  },
  PayoutMethod: {
    url: '/settings/financial/payout-method',
  },
  TaxInformation: {
    url: '/settings/financial/taxinfo',
  },
  MissingAndExpiringConditions: {
    url: '/credentials/status',
  },
};
