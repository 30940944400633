import { DialogProps } from '@medely/web-components';
import { ICondition } from '@medely/web-components/types';
import React from 'react';
import AdminOnlyQualificationDialog from '../AdminOnlyQualificationDialog';
import MissingConditionFormDialog from './MissingConditionFormDialog';
import MissingSignedDocumentDialog from './MissingSignedDocumentDialog';
import { findSignedDocumentQualification } from './useSignedDocumentQualification';
import AuthorizationDialog from '../customConditionForms/AuthorizationDialog/AuthorizationDialog';
import { isAuthorizationType } from '../utils/credentialsUtils';
import { findI9Qualification } from './useI9Qualification';
import MissingI9Dialog from './MissingI9Dialog';
import { buildFormCondition } from 'utils/professionalQualifications';
import useProfessionalQualifications from 'hooks/useProfessionalQualifications';

type CredentialUploadDialogProps = DialogProps & {
  condition: ICondition;
};

const MissingConditionDialog = ({ open, onClose, condition }: CredentialUploadDialogProps) => {
  const { allProfessionalQualifications } = useProfessionalQualifications();

  const qualification = findSignedDocumentQualification(condition);
  if (qualification) {
    return (
      <MissingSignedDocumentDialog
        qualificationId={qualification.id}
        open={open}
        onClose={onClose}
      />
    );
  }

  const i9Qualification = findI9Qualification(condition);
  if (i9Qualification) {
    return <MissingI9Dialog qualificationId={i9Qualification.id} open={open} onClose={onClose} />;
  }

  const hasExistingPQInReview = condition.qualifications.some((qual) =>
    allProfessionalQualifications.some(
      (pq) => pq.qualification.id === qual.id && pq.status === 'review',
    ),
  );

  if (isAuthorizationType(condition.qualifications[0]) && !hasExistingPQInReview) {
    return <AuthorizationDialog condition={condition} onClose={onClose} open={open} />;
  }

  if (condition.qualifications[0].admin_view_only) {
    return (
      <AdminOnlyQualificationDialog
        open={open}
        onClose={onClose}
        qualificationName={condition.qualifications[0].long_name}
      />
    );
  }

  return (
    <MissingConditionFormDialog
      open={open}
      onClose={onClose}
      condition={buildFormCondition(condition)}
    />
  );
};

export default MissingConditionDialog;
