import React from 'react';
import { IPostingFieldContents } from '@medely/types';
import { formatContentBasedOnDescription } from './postingFieldsDisplayHelpers';
import { Box, Text } from '@medely/ui-kit';

export const PostingFieldsGroup = ({
  postingFieldContents,
}: {
  postingFieldContents: IPostingFieldContents | undefined;
}) => {
  const keys = !!postingFieldContents ? Object.keys(postingFieldContents) : [];

  return (
    <Box my={2} width="100%">
      {postingFieldContents &&
        keys.map((key) => (
          <Box mt={2} key={key}>
            <Text size="m" color="text.primary" bold>
              {postingFieldContents[key].label}
            </Text>
            <Text size="m" color="text.primary" bold>
              {formatContentBasedOnDescription(
                postingFieldContents[key].description,
                postingFieldContents[key].content,
              )}
            </Text>
          </Box>
        ))}
    </Box>
  );
};
