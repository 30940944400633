import React from 'react';
import { JobDetailsListItem } from '../JobDetailsListItem';
import { Icon, Text, Link } from '@medely/ui-kit';
import { config } from '@mp/config';

type TimesheetReminderProps = {
  smallIcon?: boolean;
};

export const TimesheetReminder = ({ smallIcon = false }: TimesheetReminderProps) => {
  return (
    <JobDetailsListItem
      title="Timesheet"
      icon={
        <Icon
          name="light-calendar-check"
          iconSize={smallIcon ? '14px' : '20px'}
          color="text.primary"
          variant="custom"
        />
      }
    >
      <Text size="m" color="text.secondary">
        This facility requires you to upload an image of your signed timesheet (
        <Link url={config.help.formFaq} variant="text" size="m">
          530 form
        </Link>
        ) when clocking out.
      </Text>
    </JobDetailsListItem>
  );
};
