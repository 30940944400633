import { IJob } from '@medely/types';
import { Box, Heading, Icon, Text } from '@medely/ui-kit';
import React from 'react';
import { PostingFieldsGroup } from './PostingFieldsGroup';
import { Divider } from '../../../divider/Divider';
import { JobDetailsListItem } from '../../JobDetailsListItem';
import { HideFrom } from '../../../../components/HideFrom';
import ParkingInfoSection from './ParkingInfoSection';

type JobAdditionalDetailsProps = {
  smallIcon?: boolean;
  isAvailable?: boolean;
  job: IJob;
};

export const JobAdditionalDetails = ({
  job,
  smallIcon = false,
  isAvailable = false,
}: JobAdditionalDetailsProps) => {
  const bookedOrInProgress = () => ['booked', 'clocked_in', 'clocked_out'].includes(job.status);
  // TODO: CORE-3167 to remove this later
  const isCompany21779 = job.location.company_id === 21779;

  const cancellationProtectionMessage = isCompany21779
    ? `This job pays a $40 cancellation fee if the facility cancels the job less than ${job.cancellation_fee_window} hours prior to the start time.`
    : `This job pays a ${job.cancellation_fee_charge_hours} hour cancellation fee if the facility cancels the job less than ${job.cancellation_fee_window} hours prior to the start time.`;

  return (
    <>
      <Heading size="s" color="text.primary" testId="job-additional-details-title">
        Additional job details
      </Heading>
      {job.detail && (
        <Box mt={2}>
          <Text size="m" color="text.primary" testId="job-additional-details-job-detail">
            {job.detail}
          </Text>
        </Box>
      )}
      <Box mt={2}>
        <Text size="m" color="text.secondary" testId="job-additional-details-job-id">
          Job ID: {job.id}
        </Text>
      </Box>
      <PostingFieldsGroup postingFieldContents={job.posting_field_contents} />
      {isAvailable && (
        <>
          <Divider />
          <Box mt={3}>
            <Box testId="job-additional-details-parking">
              <ParkingInfoSection
                parkingDetails={job.location}
                bookedOrInProgress={bookedOrInProgress()}
              />
            </Box>
            <JobDetailsListItem
              title={`Scrubs are${job.scrubs_provided ? ' provided' : ' not provided'}`}
              icon={
                <Icon
                  name="light-tshirt"
                  iconSize={smallIcon ? '14px' : '20px'}
                  color="text.primary"
                  variant="custom"
                />
              }
            />
          </Box>
          <HideFrom saas>
            <Box mt={3} testId="job-additional-details-cancellation">
              <HideFrom
                agency
                fallback={
                  <JobDetailsListItem
                    title="Cancelation policy"
                    icon={
                      <Icon
                        name="light-shield-check"
                        iconSize={smallIcon ? '14px' : '20px'}
                        color="text.primary"
                        variant="custom"
                      />
                    }
                  >
                    <Text size="m" color="text.secondary">
                      You can cancel a shift without penalty within the first 30 minutes of booking
                      or more than 24 hours before the scheduled start time.
                    </Text>
                  </JobDetailsListItem>
                }
              >
                <JobDetailsListItem
                  title="Cancellation Protection"
                  icon={
                    <Icon
                      name="light-shield-check"
                      iconSize={smallIcon ? '14px' : '20px'}
                      color="text.primary"
                      variant="custom"
                    />
                  }
                >
                  <Text size="m" color="text.secondary">
                    {cancellationProtectionMessage}
                  </Text>
                </JobDetailsListItem>
              </HideFrom>
            </Box>
          </HideFrom>
        </>
      )}
    </>
  );
};
