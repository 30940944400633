import React from 'react';
import { Text } from '@medely/ui-kit';
import { HelpCenterLink } from '../helpCenterLink';

export const EstimatedPayDisclaimer = () => {
  return (
    <Text size="m" color="text.secondary" testId="job-compensation-hours-estimated">
      Total estimated pay is based on shift length provided by the facility. Actual pay may be
      higher or lower based on the number of hours actually worked.{' '}
      <HelpCenterLink configKey="weekly1099ExtendedRate" text="Learn more" />
    </Text>
  );
};
